/* Colors */
.bg-primary {
  background: var(--color-primary);
}

.bg-primary--accent {
  background: var(--color-primary-accent) !important;
}

.bg-secondary {
  background: var(--color-secondary-jungleblack);
}

.bg-secondary-light {
  background: var(--color-secondary-lightgray);
}

.color-secondary {
  color: var(--color-primary-accent);
}

.color-primary {
  color: var(--color-secondary-jungleblack);
}

.color-charcoal { color: var(--color-secondary-charcoal) }

.color-darkgray { color: var(--color-secondary-darkgray) }

.color-tertiary {
  color: var(--color-tertiary-blue-light);
}

.color-white {
  color: #fff;
}

.main-title {
  font-size: var(--font-size-50);
  font-weight: 700;
}

.second-title {
  font-size: var(--font-size-32);
  font-weight: 700;
}

.lockup-container {
  width: calc(100% - 32px);
  max-width: 1170px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0 var(--space-16);

  @media (max-width: 1023px) {
    width: 100%;
  }
}

.text-center {
  text-align: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.flex-gap-16 { gap: var(--space-16) }

.row-gap-28 { row-gap: var(--space-28) }

.ft-size-12 { font-size: var(--font-size-12) }

.ft-size-14 { font-size: var(--font-size-14) }

.ft-size-16 { font-size: var(--font-size-16) }

.ft-size-18 {
  font-size: var(--font-size-18)
}

.ft-size-24 {
  font-size: var(--font-size-24)
}

.italic {
  font-style: italic;
}

// Position
.position-relative {
  position: relative;
}

.center-content {
  width: 60%;
  margin: auto;
  padding: 140px 0;
}

.mlr-auto {
  margin-left: auto;
  margin-right: auto;
}

/* Override BS .container */
@media (min-width: 1024px) {
  .container {
    max-width: 970px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}

@media (min-width: 1024px) {
  .col-lg-offset-6 {
    margin-left: 50%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container-sm-12 {
    max-width: 100% !important;
  }
}

@media (max-width: 540px) {
  .col-xs-1 {
    flex: 0 0 auto;
    width: 8.33333333% !important;
  }
  .col-xs-2 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }
  .col-xs-3 {
    flex: 0 0 auto;
    width: 25% !important;
  }
  .col-xs-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }
  .col-xs-5 {
    flex: 0 0 auto;
    width: 41.66666667% !important;
  }
  .col-xs-6 {
    flex: 0 0 auto;
    width: 50% !important;
  }
  .col-xs-7 {
    flex: 0 0 auto;
    width: 58.33333333% !important;
  }
  .col-xs-8 {
    flex: 0 0 auto;
    width: 66.66666667% !important;
  }
  .col-xs-9 {
    flex: 0 0 auto;
    width: 75% !important;
  }
  .col-xs-10 {
    flex: 0 0 auto;
    width: 83.33333333% !important;
  }
  .col-xs-11 {
    flex: 0 0 auto;
    width: 91.66666667% !important;
  }
  .col-xs-12 {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .d-xs-none { display: none !important }
  .d-xs-block { display: block !important; }
}

/* Paddings and margins */
@for $i from 1 through 20 {
  .pt-8-#{$i} {
    padding-top: calc(8px * $i) !important;
  }

  .pb-8-#{$i} {
    padding-bottom: calc(8px * $i) !important;
  }

  .mt-8-#{$i} {
    margin-top: calc(8px * $i) !important;
  }

  .mb-8-#{$i} {
    margin-bottom: calc(8px * $i) !important;
  }
}

@media (min-width: 1024px) {
  @for $i from 1 through 20 {
    .pt-l8-#{$i} {
      padding-top: calc(8px * $i) !important;
    }

    .pb-l8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }
  }
}

@media (max-width: 1023px) {
  @for $i from 1 through 20 {
    .pt-m8-#{$i} {
      padding-top: calc(8px * $i) !important;
    }

    .pb-m8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }

    .pl-m8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }

    .pr-m8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }

    .mt-m8-#{$i} {
      margin-top: calc(8px * $i) !important;
    }

    .mb-m8-#{$i} {
      margin-bottom: calc(8px * $i) !important;
    }
  }
}

@media (max-width: 767px) {
  @for $i from 1 through 20 {
    .pt-s8-#{$i} {
      padding-top: calc(8px * $i) !important;
    }

    .pb-s8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }

    .mt-s8-#{$i} {
      margin-top: calc(8px * $i) !important;
    }

    .mb-s8-#{$i} {
      margin-bottom: calc(8px * $i) !important;
    }
  }

  @for $i from 1 through 40 {
    .mt-s4-#{$i} {
      margin-top: calc(4px * $i) !important;
    }

    .mb-s4-#{$i} {
      margin-bottom: calc(4px * $i) !important;
    }
  }
}

@media (min-width: 1024px) {
  @for $i from 1 through 20 {
    .pt8-#{$i} {
      padding-top: calc(8px * $i) !important;
    }

    .pb8-#{$i} {
      padding-bottom: calc(8px * $i) !important;
    }
  }
}

/* Layout classes */
.row {--mdb-gutter-x: 1.5rem;--mdb-gutter-y: 0;display: flex;flex-wrap: wrap;margin-top: calc(-1 * var(--mdb-gutter-y));margin-right: calc(-0.5 * var(--mdb-gutter-x));margin-left: calc(-0.5 * var(--mdb-gutter-x))}

.row > * {flex-shrink: 0;width: 100%;max-width: 100%;padding-right: calc(var(--mdb-gutter-x) * 0.5);padding-left: calc(var(--mdb-gutter-x) * 0.5);margin-top: var(--mdb-gutter-y)}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding-right: var(--mdb-gutter-x, 0.75rem);
  padding-left: var(--mdb-gutter-x, 0.75rem);
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

@media (min-width: 576px) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / (12 / $i));
    }
  }
}

@media (min-width: 768px) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / (12 / $i));
    }
  }

  .d-md-block {
    display: block;
  }

  .d-md-none {
    display: none;
  }
}

@media (min-width: 992px) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / (12 / $i));
    }
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-none {
    display: none;
  }
}

@media (min-width: 1200px) {
  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      flex: 0 0 auto;
      width: calc(100% / (12 / $i));
    }
  }
}
