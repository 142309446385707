.Header {
  width: 100%;
  height: var(--main-header-height, 135px);
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 500;

  &.expanded {
    @media screen and (max-width: 1024px) {
      max-height: 1500px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    max-height: 130px;
    overflow: hidden;
    background: transparent;
    transition: max-height 0.4s ease-in-out;
    padding-bottom: 50px;
  }

  &__MobileHead {
    width: 100%;
    height: 130px;
    margin: 0 auto;
    padding: 0 20px;
    background: #fff;
    border-bottom: 1px solid rgba(88, 90, 110, 0.2);

    @media screen and (max-width: 1024px) {
      display: flex !important;
    }

    &__MenuIcon {
      $self-menu: &;
      border: 0;
      padding: 0;
      height: 12px;
      position: relative;
      background: none;

      @mixin bars {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--color-onyx);
        transition: all 0.35s ease-in-out;
      }

      &::before {
        @include bars;
        top: 0;
        left: 0;
      }

      &::after {
        @include bars;
        bottom: 0;
        left: 0;
      }

      &.menuExpanded {
        span {
          transform: scale(0);
        }
      }

      &.menuExpanded:before {
        transform: rotate(-45deg);
        bottom: 0;
        margin: auto;
      }

      &.menuExpanded:after {
        transform: rotate(45deg);
        top: 0;
        margin: auto;
      }

      span {
        width: 18px;
        height: 2px;
        display: block;
        background: var(--color-onyx);
        transition: transform 0.4s ease;
      }

      &__Ser {
        width: 18px;
        height: 2px;
        padding-bottom: 12px;
      }
    }
  }

  &__Content {
    width: calc(100% - 48px);
    height: 100%;
    max-width: 1170px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      overflow: auto;
      max-height: calc(100vh - 80px);
      width: 100%;
      height: auto;
      padding: 0 20px 48px;
      background: #fff;
      border-bottom-right-radius: 80px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.10) !important;
    }
  }

  &__LeftSide {
    align-items: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-wrap: wrap;
      margin-bottom: var(--space-24);
    }

    &__Logo {
      box-shadow: none;

      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }

    &__NavTabsBox {
      height: 100%;
      margin: 0 0 0 30px;
      padding: 0;
      list-style: none;
      font-weight: 600 !important;
      gap: 15px;

      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }

    &__NavTabsBoxMobile {
      width: 100%;
      height: auto;
      margin: 0;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

    }
  }

  &__RightSide {
    column-gap: 15px;
    justify-content: center;
    align-items: center !important;

    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      margin: 0 auto;
      gap: 16px;
    }

    &__LoginBtn {
      button {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 15px 0;

        &:hover {
          transition: all 0.3s;
          color: rgba(0, 102, 255, 0.5);
          background: none;
        }

        &:active {
          color: #0052CC !important;
          transition: all 0.3s;
        }
      }

      @media screen and (max-width: 1024px) {
        margin: 0 auto;
      }
    }

    &__ViewDemoBtn {
      button {
        border-radius: 8px;
        border: 2px solid #06F;

        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 15px;

        &:hover {
          transition: all 0.3s;
          border: 2px solid rgba(0, 102, 255, 0.5);
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #06F;
        }

        &:active {
          background: #0052CC !important;
          transition: all 0.3s;
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &_Mobile {
        display: none;

        @media screen and (max-width: 1024px) {
          display: block;
          button {
            width: 100%;
            border-radius: 8px;
            border: 2px solid #06F;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            padding: 16px 8px;
          }
        }

        @media screen and (max-width: 370px) {
          button {
            padding: 8px 2px;
          }
        }

      }
    }


    &__TrialBtn {
      button {
        border-radius: 8px !important;
        border: 2px solid #04D464;
        font-size: 20px;
        padding: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: var(--color-lime-green) !important;

        &:hover {
          border: 2px solid #04D464 !important;
          transition: all 0.3s ease;
          opacity: 0.8;
        }

        &:active {
          color: #03AA50 !important;
          border: 2px solid #03AA50 !important;
          transition: all 0.3s ease;
        }
      }

      @media screen and (max-width: 1024px) {
        margin: 0 auto;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}
