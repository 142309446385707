:root {
  /* Colors */
  --color-primary: #27c26c;
  --color-primary-dark: #21A75D;
  --color-primary-accent: #eb4e29;

  --color-secondary-jungleblack: #2c2e3c;
  --color-secondary-charcoal: #585a6e;
  --color-secondary-darkgray: #a4a7b2;
  --color-secondary-lightgray: #edeef2;
  --color-gray-info: #6d6f78;

  --color-tertiary-saffron: #f7b82f;
  --color-tertiary-saffron-light: #fce3ac;
  --color-tertiary-blue: #3c4ad9;
  --color-tertiary-blue-light: #b1b7f0;
  --color-error: #d02839;
  --color-error-light: #f22f42;
  --color-footer-default: #4c4d5e;

  --color-link-default: #0077b7;
  --color-link-orange: #f46736;
  --color-link-orange-light: #de6c2d;
  --color-link-gray: #585a6e;
  --color-link-active-white: #dcdde1;

  --color-paragraph-card: #4b546b;
  --color-subtitle-lightgray: #38415B;
  --color-icon-check-orange: #eb5d3d;

  --color-background-section-gray: #edeef2;

  /* New Palette Colors */

  //Primary
  --color-ocean-blue: #0066FF;
  --color-lime-green: #04D464;

  //Secondary
  --color-teal: #37EEB7;
  --color-lemon: #FCED6D;
  --color-forest: #0F552B;
  --color-ash: #574841;

  // Base colors
  --color-white: #FFFFFF;
  --color-onyx: #342B27;
  --color-silver: #F1EFED;
  --color-gray: #B3AEAB;

  // Sentiment
  --color-positive: #15783D;
  --color-negative: #D81E3A;
  --color-warning: #FBC02D;


  /* Typography */
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-32: 2rem;
  --font-size-50: 3.125rem;

  --space-4: 0.25rem;
  --space-8: 0.5rem;
  --space-10: 0.625rem;
  --space-12: 0.75rem;
  --space-16: 1rem;
  --space-20: 1.25rem;
  --space-24: 1.5rem;
  --space-28: 1.75rem;
  --space-32: 2rem;

  // Size
  --main-header-height: 135px;
  --main-header-height-mobile: 130px;
}

