form {
  .link {
    color: #0077b7;
    cursor: pointer;

    &:hover {
      color: #0077b7 !important;
    }
  }
}

/* Form Input */
.form-input-container {
  position: relative;

  &__error-underneath {
    min-height: 20px;
    position: relative;
    padding: 0 var(--space-20) var(--space-8);
    box-sizing: content-box;
    font-size: var(--font-size-12);
    line-height: 1.5;
    color: var(--color-error-light);
    text-align: left;

    .link {
      color: #0077b7;
      cursor: pointer;
    }
  }
}

.form-input-wrapper {
  $self: &;
  height: 45px;
  padding: var(--input-wrapper-pd, 0 var(--space-20) 0);
  border-radius: 26px;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background-color: #e5e6e9;

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px #e5e6e9 inset;
    }
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;
    border-radius: 26px;

    &::after {
      content: '';
      width: 100%;
      border-bottom: 1px solid;
      border-bottom-color: rgba(0, 0, 0, 0.42);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__input {
    width: 100%;
    font-size: var(--font-size-18);
    line-height: 24px;
    padding-right: var(--input-pdr, var(--space-20));
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    z-index: 2;

    &::placeholder {
      color: var(--color-secondary-jungleblack);
      font-size: 15px;
      font-weight: 400;
      opacity: 1; // Make sure this is set for firefox to display it well
    }

    &:focus::placeholder {
      color: var(--color-secondary-darkgray);
    }

    &:focus ~ #{ $self }__border {
      &::after {
        border-bottom-width: 2px !important;
      }
    }
  }

  &__icon {
    display: none !important;
    background-size: contain;
    width: var(--form-icon-size, 24px);
    height: var(--form-icon-size, 24px);
    position: absolute;
    top: 0;
    bottom: 0;
    right: var(--space-16);
    margin: auto 0;
    z-index: 5;

    &--wrong {
      color: var(--color-error-light);
    }

    &--valid {
      color: var(--color-primary);
    }

    &--reveal {
      display: block !important;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.54) !important;
      width: 24px;
      height: 24px;
    }
  }

  &:focus-within {
    background: rgba(0, 0, 0, .15);

    &:hover {
      background: rgba(0, 0, 0, .15);
    }
  }

  &--error {
    #{ $self }__border {
      &::after {
        border-bottom-color: var(--color-error-light);
      }
    }

    #{ $self }__label {
      color: var(--color-error-light) !important;
    }

    #{ $self }__icon--reveal {
      color: var(--color-error-light) !important;
    }

    #{$self}__icon--wrong {
      display: inline-block !important;
    }

    #{$self}__icon--valid {
      display: none !important;
    }
  }

  &--valid {
    #{ $self }__border {
      &::after {
        border-bottom-color: var(--color-primary);
      }
    }

    #{ $self }__icon--reveal {
      color: var(--color-primary) !important;
    }

    #{$self}__icon--wrong {
      display: none !important;
    }

    #{$self}__icon--valid {
      display: inline-block !important;
    }
  }
}
