///* Tooltip override from BS */
.tooltip-td {
  color: var(--tooltip-color, #fff) !important;
  padding: 0.4rem var(--space-20) !important;
  display: none;
  opacity: 1 !important;
  position: absolute;

  &.top {
    bottom: calc(100% + var(--space-4));
    left: 0;
    right: 0;

    .tooltip-arrow {
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 14px;
      height: .4rem;
    }
  }

  .tooltip-inner, p {
    display: block;
    margin: 0 auto;
    padding: var(--space-8) var(--space-12);
    font-size: var(--tooltip-font-size, 13px) !important;
    line-height: 1.2rem !important;
    color: var(--tooltip-text-color, #fff) !important;
    text-align: var(--tooltip-align, left);
    background: var(--tooltip-bg, var(--color-error));

    .sign-in-link {
      color: yellow;
      font-weight: 700;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .tooltip-arrow {
    display: block !important;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      border-color: transparent;
      border-top-color: var(--tooltip-bg, var(--color-error)) !important;
      border-width: .4rem .4rem 0;
      border-style: solid;
      bottom: 0;
    }
  }

  &.show {
    display: block !important;
  }
}
