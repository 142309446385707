/* Footer */
footer {
  .footer-content > .row {
    row-gap: var(--space-28);
  }

  .footer-bot {
    margin-top: 53px;

    .nav li {
      margin: 0 20px 0 0;

      a {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    .footer-bottom-right {
      display: flex;
      justify-content: flex-end;

      li {

      }
    }

    .footer-bottom-left {
      display: flex;
    }
  }

  // Integrations column
  .integrations-column {
    margin-top: -80px;
    display: flex;
    justify-content: flex-end;

    .integrat {
      padding-left: var(--space-12);

      .nav {
        display: flex !important;
        flex-wrap: wrap;
        row-gap: 10px;
        margin-top: 24px;

        li {
          display: inline-block;
          margin: 0 8px 0 0;
          width: auto;

          a {
            position: relative;
            padding: 0 16px;
            border-radius: 0;
            line-height: 30px;
            font-size: var(--font-size-14);

            &:hover {
              color: #ff7c4f;

              &::before {
                border-color: #ff7c4f;
              }
            }

            &::before {
              width: 100%;
              height: 30px;
              border: 1px solid #a4a7b2;
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              border-radius: 4px;
            }
          }

          &:last-child {
            margin-top: 8px;
            display: block;

            a {
              &::before {
                border: 0;
              }
            }
          }
        }

        .lastPillWrapper {
          display: flex !important;
          flex: 1 1 100%;
          margin-left: -10px;
          margin-top: 20px;
          border: none !important;
          align-items: center;

          a {
            display: flex;
            align-items: center;
            column-gap: 4px;
          }
        }

        .integrationsPill {
          border: none;
        }
      }
    }
  }

  // Nav column
  .nav-column {
    &__header {
      display: flex;
      align-items: center;

      &__title {
        font-size: 20px;
        font-weight: 900;
        line-height: 20px;
        color: var(--color-gray);
        letter-spacing: 0.29px;
        cursor: default;
      }

      &__expand-btn {
        display: none; // This btn is not displayed on desktop or tablet
        width: 24px;
        height: 24px;
        padding: 0;
        background: none;
        border: none;
        color: #fff;
        cursor: pointer;
      }
    }

    &__content {
      padding: 0;
      margin: 0;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      li {
        text-align: left;
        margin-top: var(--space-16);
        vertical-align: top;
        display: block;
        list-style: none;

        a {
          display: block;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: var(--color-white) !important;
          letter-spacing: .25px;
          padding: 0;

          &:hover {
            transition: all 0.3s ease;
            opacity: 0.8;
          }
        }
      }
    }
  }

  width: 100%;
  background: var(--color-ash);
  padding: 90px 135px;

  .nav-pills {
    margin-left: 0 !important;
    flex-wrap: wrap;
  }

  .nav {
    padding-left: 0;
    display: block;

    li {
      text-align: left;
      margin-top: var(--space-16);
      vertical-align: top;
      display: block;
    }
  }

  .nav li a {
    display: block;
    color: var(--color-secondary-darkgray);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .25px;
    padding: 0;

    &:hover {
      color: #ff7c4f !important;
    }
  }

  .tw i {
    font-size: 24px;

    &:hover {
      color: #ff7c4f;
    }
  }

  .fb i {
    font-size: 24px;

    &:hover {
      color: #ff7c4f;
    }
  }

  @media (max-width: 320px) {
    padding: 24px 0;
  }
}

@media (min-width: 1200px) {
  footer {
    .integrations-column {
      margin-left: auto;
    }
  }
}

/* tablets */
@media (min-width: 768px) and (max-width: 1199px) {
  footer {
    padding: 64px 90px;
    .footer-bot {
      padding-top: 40px;
      margin-top: 0;
    }

    .features-column {
      margin-bottom: 40px;
    }

    .integrations-column {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;

      .integrat { padding-left: 0; }
    }

    .footer-bottom-right {
      display: flex;
      justify-content: flex-start !important;

      li {
        margin-right: 20px;
      }
    }

    .footer-bottom-left {
      display: flex;
      justify-content: flex-start !important;

      li {
        margin-right: 20px;
      }
    }

    /* Company Links */
    .company-links {
      padding-top: 0;

      nav li a {
        font-size: var(--font-size-14);
      }
    }

    .title-tablet {
      margin-bottom: 2px;
    }

    .column-tablet {
      margin-bottom: 35px;
    }
  }
}

/*   MOBILE STYLES*/
@media (min-width: 320px) and (max-width: 767px) {
  footer {
    padding: 64px 24px;
    .footer-content > .row {
      --mdb-gutter-x: -2rem;
      row-gap: 0;
    }

    .integrations-row {
      padding: 0;
      margin: 0;
    }

    .integrations-column {
      margin-top: 0;
      padding: 0;

      .integrat {
        padding-left: 0;

        .nav-pills {
          margin-top: 0;

          li {
            a {
              font-size: var(--font-size-14);
            }
          }

          .lastPillWrapper {
            display: flex;
            flex: 1 1 100%;
            margin-left: 0;
            margin-top: var(--space-12);

            a {
              padding-left: 0;
            }

            &:hover {
              color: var(--color-secondary-jungleblack);
              border: none !important;
            }
          }
        }
      }
    }

    // Nav column
    .nav-column {
      $self: '.nav-column';
      overflow: hidden;
      max-height: 32px;
      margin-bottom: var(--space-24);
      transition: max-height 0.4s ease;

      &.expanded {
        max-height: 500px;

        #{$self}__header {
          &__expand-btn {
            transform: rotate(180deg);
          }
        }
      }

      &__header {
        width: 100%;
        justify-content: space-between;

        &__expand-btn {
          display: block;
          margin-right: var(--space-4);
          transition: transform 0.4s ease;
        }
      }

      &__content {
        padding: 24px 0;

        li:first-child {
          margin: 0;
        }
      }
    }

    .footer-bot {
      margin-top: var(--space-24);

      .nav li {
        margin: 0;

        a {
          line-height: 1;
        }
      }

      /* Compliance Links */
      .footer-bottom-left {
        row-gap: 16px;

        li:first-child {
          width: 100%;
        }

        li {
          width: 50%;
          margin: 0;
        }
      }

      /* Company Links */
      .company-links {
        padding-top: 45px;

        .footer-bottom-right {
          justify-content: left;

          li {
            width: 50%;

            &.icon {
              margin-top: var(--space-24);
              width: auto;
            }
          }

          .fb {
            margin-right: 20px;
          }
        }
        nav li a {
          font-size: var(--font-size-14);
        }
      }
    }
  }

  .footerMobile {


    .social-icons {
      margin-left: 0;
    }

    .footer-bot {
      padding-top: var(--space-24);
      margin-top: 0;
    }
  }
}
