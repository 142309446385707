.ReviewBadge {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  align-items: flex-start;

  & .StarIcon {
    font-size: var(--star-ft-size, 18px);
  }

  & .SvgWrap {
    display: flex;
    align-items: flex-end;
    width: var(--svg-width, 95px);
    height: var(--svg-height, 24px);

    & > svg path {
      fill: var(--badge-color, var(--color-gray-info));
    }
  }
}
