.NavItem {
  $self: &;
  display: block;
  max-width: 260px;
  border-radius: 8px;
  box-sizing: content-box;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  &:hover, &:focus-visible {
    #{$self}__Title {
      color: var(--color-primary) !important;
    }
  }

  &__ItemName {
    font-size: 20px !important;
    margin-bottom: 15px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    color: var(--color-onyx);

    &:hover {
      color: var(--color-ocean-blue);
      transition: all 0.3s ease;
    }

    @media screen and (max-width: 1024px) {
      color: var(--color-onyx) !important;
      font-weight: 400 !important;
      line-height: 28px !important;
      margin-bottom: 0 !important;
    }
  }

  &__Desc {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
