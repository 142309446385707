@font-face {
  font-family: 'Articulat CF Extra';
  src: url('../../../public/fonts/ArticulatCF/100-ArticulatCF-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Extra';
  src: url('../../../public/fonts/ArticulatCF/100-ArticulatCF-ThinOblique.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Extra';
  src: url('../../../public/fonts/ArticulatCF/200-ArticulatCF-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Extra';
  src: url('../../../public/fonts/ArticulatCF/200-ArticulatCF-ExtraLightOblique.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/300-ArticulatCF-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/300-ArticulatCF-LightOblique.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Normal';
  src: url('../../../public/fonts/ArticulatCF/400-ArticulatCF-Normal.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/400-ArticulatCF-NormalOblique.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/400-ArticulatCF-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/400-ArticulatCF-RegularOblique.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/500-ArticulatCF-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/500-ArticulatCF-MediumOblique.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Demi';
  src: url('../../../public/fonts/ArticulatCF/600-ArticulatCF-DemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Demi';
  src: url('../../../public/fonts/ArticulatCF/600-ArticulatCF-DemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/700-ArticulatCF-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/700-ArticulatCF-BoldOblique.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Extra';
  src: url('../../../public/fonts/ArticulatCF/800-ArticulatCF-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF Extra';
  src: url('../../../public/fonts/ArticulatCF/800-ArticulatCF-ExtraBoldOblique.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/900-ArticulatCF-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('../../../public/fonts/ArticulatCF/900-ArticulatCF-HeavyOblique.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
