/* Button block */
.button-td {
  font-size: var(--button-font-size, var(--font-size-14));
  font-weight: 500;
  line-height: 1.4;
  padding: var(--space-16) var(--space-28);
  border: 0;
  border-radius: 26px;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    opacity: .65;
  }

  &:hover {
    background: #e28450 !important;
  }

  &:focus-visible {
    outline: 2px solid black !important;
  }

  &[data-state='link'] {
    display: block;
    position: relative;
    padding: var(--space-8) var(--space-20);
    font-size: var(--font-size-16);
    font-weight: 700;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: var(--color-secondary-jungleblack);
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover {
      color: var(--color-secondary-jungleblack) !important;
      background: transparent !important;

      &::before {
        transform: scaleX(1);
      }
    }
  }

  &[data-state='bordered'] {
    font-size: var(--font-size-16);
    padding: var(--space-8) var(--space-20);
    border: 2px solid var(--color-secondary-jungleblack);
    background: transparent !important;

    &:hover {
      opacity: 1;
      background: #ccc !important;
    }
  }

  &[data-state='filled'] {
    font-size: var(--font-size-16);
    padding: var(--space-8) var(--space-20);
    color: #fff;
  }
}

.button-td-orange {
  background: var(--color-link-orange) !important;
  color: #fff !important;
  border: none;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 28px;
  border-radius: 5px;
  border: 0;
  transition: background .5s ease, box-shadow .6s ease;
  margin-top: 16px;
  transition-duration: 0.4s;
  box-shadow: -1px 9px 33px -2px rgb(204 110 43 / 53%);
}

