.SignupFormV2 {
  width: 100%;

  &__InputsWrapper {
    row-gap: var(--signup-form-row-gap, 0);
    width: var(--signup-form-width, auto);
    margin: 0 auto;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      max-width: 100%;
      margin: 0 auto;
      row-gap: unset;
      width: 100%;
    }
  }

  &__CompanySize {
    height: 33px;
    border-radius: 15px !important;
    text-align: left;
    margin-bottom: 20px;
    font-size: 16px !important;
    color: #B3AEAB !important;
    background-color: #FFFFFF;

    font-style: normal;
    font-weight: 500 !important;
    line-height: 30px !important;
  }

  .validPhoneNumber {
    border-color: #27c26c;
  }

  .invalidPhoneNumber {
    border-color: red;
  }

  .CreateAccountBtn {
    margin: 0 auto;
    display: block;
    background-color: #0066FF;
    border-radius: 8px;
    border: 2px solid #06F;

    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px;

    &:hover {
      transition: all 0.3s;
      border: 2px solid rgba(0, 102, 255, 0.5);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #06F;
    }
  }
}

