@import "scss/font";

$textColor: #2c2e3c;


html,
body {
  padding: 0;
  margin: 0;

  --mdb-gutter-x: 1rem;
}

* {
  font-family: 'Articulat CF', 'Articulat CF Extra', 'Articulat CF Normal', 'Articulat CF Demi', sans-serif !important;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-secondary-jungleblack);
  margin-bottom: 0;
  margin-block-start: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit !important;
  }
}

p {
  font-size: var(--p-font-size, var(--font-size-16));
  line-height: 28px;
  letter-spacing: 0.14px;
  color: var(--color-secondary-jungleblack);
  margin-bottom: 0;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

input {
  outline: 0;
  border: 0;
  background: transparent;
}

input::-ms-clear,
input::-ms-reveal {
  width: 0;
  height: 0
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px var(--color-secondary-lightgray) inset;
  transition: background-color 0s;
}

button:not(:disabled) {
  cursor: pointer;
}

.align-middle {
  display: flex;
  align-items: center;
}

.center-items {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* FEATURES SECTION */
.features {
  background: url('../../public/images/home-page/feat-right-dots-xl.png'),
    url('../../public/images/home-page/feat-left-dots-xl.png'),
    url('../../public/images/home-page/feat-left-bar-xl.png'),
    url('../../public/images/home-page/triangle-up.png');
  background-repeat: no-repeat;
  background-position: top 0 right 0, top 45% left 0, top 70% left 0, bottom 0 right 10%;

  p {
    line-height: 28px;
    letter-spacing: 0.14px;
    color: var(--color-secondary-jungleblack);
    margin-bottom: 0;
    font-size: 18px;
  }

  background-color: #edeef2;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
  position: relative;
  margin-top: 100px;

  .featuresTitle {
    font-size: 42px;
    line-height: 49px;
    font-weight: 700;
    margin-top: 100px;
  }

  .featuresSubheading {
    line-height: 28px;
    letter-spacing: 0.14px;
    margin-bottom: 0;
    font-size: 18px;
  }

  .managers {
    margin-top: 110px;

    .better-managers-title {
      font-size: 32px;
      letter-spacing: 0.29px;
      line-height: 38px;
      margin-bottom: 24px;
      font-weight: 700;
    }
  }

  .employees {
    margin-top: 110px;

    .better-employees-title {
      font-size: 32px;
      letter-spacing: 0.29px;
      line-height: 38px;
      margin-bottom: 24px;
      font-weight: 700;
    }
  }

  .organization {
    margin-top: 110px;
    margin-bottom: 110px;
    padding-bottom: 100px;

    .better-organization-title {
      font-size: 32px;
      letter-spacing: 0.29px;
      line-height: 38px;
      margin-bottom: 24px;
      font-weight: 700;
    }
  }
}

/* SOLUTIONS SECTION */
.solutions {
  margin-bottom: 70px;

  p {
    color: var(--color-secondary-jungleblack);
    font-size: 18px;
    margin: 0;
    line-height: 28px;
    letter-spacing: 0.14px;
  }

  .solutionsTitle {
    font-size: 42px;
    font-weight: 700;
  }

  .our-solutions {
    margin-top: 80px;

    p {
      color: #2c2e3c;
      font-size: 18px;
      margin: 0;
      line-height: 28px;
      letter-spacing: 0.14px;
    }

    .solTitle {
      color: $textColor;
      font-size: 32px;
      margin: 0 0 24px 0;
      padding-bottom: 0;
      font-weight: 700;
    }

    .icon {
      width: 72px;
      height: 72px;
      padding: 11px;
      border-radius: 38px;
      margin-bottom: 16px;
    }

    .first {
      background: #a9e7c4;
    }

    .second {
      background: #f7b8a9;
    }

    .third {
      background: #fce3ac;
    }
  }
}

/* SECURITY SECTION */
.enterprise {
  margin-bottom: 110px;
  padding: 0;
  color: #fff;

  p:not(.heading) {
    color: #fff;
    font-size: var(--font-size-18);
    margin-bottom: var(--space-16);
  }

  .container {
    background-color: #3c4ad9;
    border-radius: 10px;
    padding: 0;

    .row {
      --mdb-gutter-x: var(--space-32);
    }
  }

  .heading {
    text-transform: uppercase;
  }

  .enterprise-title {
    font-size: 32px;
    color: #fff;
    line-height: 38px;
    font-weight: 700;
    margin: 8px 0 20px 0;
    padding-bottom: 0;
  }

  .d-flex .check-icon {
    margin-right: 10px;
  }

  .left-col {
    padding: 0 16px 0 60px;
  }

  .right-col {
    padding: 40px 40px 10px 36px;
    background: url('../../public/images/home-page/security-bg-dots.png') no-repeat left bottom;
  }
}

/* FEEDBACK SECTION */

.feedback {
  padding-top: 70px;
  padding-bottom: 5%;
  margin: 0;
  width: 1170px;
  max-width: 100%;

  >.row {
    margin: 0;
  }

  p {
    margin: 24px 0;
    color: $textColor;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.14px;
  }

  .feedback-title {
    font-size: 42px;
    font-weight: 700;
    color: $textColor;
    line-height: 49px;
    margin-top: 40px;
  }

  .left-col {
    padding-right: 40px;
    padding-left: 0;

    @media(min-width: 1024px) {
      width: 40%;
    }

    .rating {
      height: 75px;
      overflow: hidden;
    }
  }

  .right-col {
    background: url('/_static/svg/feedback-triangle.svg');
    background-position: right 0 bottom 5%;
    background-repeat: no-repeat;

    @media(min-width: 1024px) {
      width: 60%;

      .client-img {
        width: 90%;
        object-fit: contain;
      }
    }

    p {
      color: #fff;
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 40px;
    }

    background-color: #27c26c;
    border-radius: 10px;

    .img_div {
      position: relative;
      height: 100%;
    }

    .dots {
      width: auto;
      height: 65%;
      position: absolute;
      top: 30% !important;
      left: -10px;
      z-index: 8;
    }

    .client-img {
      border-radius: 80px 0 80px 0 !important;
      background: transparent !important;
      position: absolute;
      top: -43px;
      left: 25px;
      z-index: 9;

      @media screen and (max-width: 767px) {
        position: relative;
        left: var(--space-20) !important;
      }
    }

    .feedback-col {
      padding: 71px 25px 50px 0;

      .triangle {
        position: absolute;
        right: 0;
        bottom: 5%;
      }
    }

    .name {
      color: #fff;
      font-size: 18px;
      margin: 0;
      padding-bottom: 0;
      font-weight: 700;
    }

    .designation {
      color: #fff;
      font-size: 18px;
      margin: 0 0 30px;
      font-weight: 400;
    }
  }
}

/* TRY IT OUT SECTION*/
.tryout {
  --button-font-size: var(--font-size-16);

  background-color: #2c2e3c;
  color: #fff;
  padding: 100px 0;
  background-image: url('/_static/svg/tryout-bottom-left.svg'),
    url('/_static/svg/tryout-bottom-right.svg'), url('/_static/svg/tryout-top-triangle.svg'),
    url('/_static/svg/tryout-bottom-triangle.svg'), url('/_static/svg/tryout-dots.svg');
  background-position: top -54px left -265px, top 5px right 0, top 0 left 65%, bottom 0 left 65%, bottom 0 right 10px;
  background-repeat: no-repeat;
  border-radius: 100px 0 100px 0;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    padding-top: var(--space-16);
  }

  p {
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.14px;
  }

  .tryout-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: var(--space-10);
  }

  .no-credit-card {
    color: #8c8f99;
    margin: 16px 0 16px;
    font-size: var(--font-size-14);
  }

  .button-td {
    width: 160px;
  }
}

/* tablets */
@media (min-width: 768px) and (max-width: 1024px) {

  // Override BS container
  .container {
    max-width: 750px !important;
  }

  .intro {
    background-image: url('/_static/svg/questions-left-pattern.svg'),
      url('/_static/svg/questions-right-pattern.svg');
    background-position: top 80% left -85px, top 100% right -85px;

    .tdLogoMobile {
      display: none;
    }

    .signUpForm {
      display: flex;
      flex-direction: column;
      align-content: center;
      width: 100%;

      .inputDiv {
        width: 100%;
        padding-bottom: 10px;
      }
    }
  }

  .feedback {
    .right-col .client-img {
      position: relative;
      top: -43px;
      left: 22px;
      z-index: 9;
    }

    .left-col {
      margin-bottom: 77px;
    }
  }

  .enterprise {
    margin-bottom: 0;

    .right-col {
      background: none;
    }
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .features {
    .container {
      max-width: 100%;
    }

    .featuresTitle {
      font-size: 40px !important;
      margin-bottom: var(--space-8);
    }
  }

  .solutions {
    .our-solutions {
      .solTitle {
        font-size: var(--font-size-32);
      }
    }
  }

  .enterprise {
    .left-col {
      padding: 0 var(--space-16) 0 !important;
    }
  }

  .feedback {
    margin: 30px 20px;
    max-width: calc(100% - 40px);

    .left-col {
      padding: 0 !important;

      .row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 0 !important;
        width: 100%;
        gap: 20px;
        max-width: 380px;

        .col-xs-6 {
          width: 100% !important;
          padding: 0 !important;

          .rating {
            .stars img {
              width: 100%;
              margin-bottom: 5px;
            }

            div:last-child {
              height: 30px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
      }
    }

    .right-col {
      .client-img {
        margin-left: -1.25rem;
      }

      .feedback-col {
        padding: 0 0 40px;
        margin-top: 0 !important;
      }

      p {
        margin-top: -10px;
      }
    }
  }
}

/*   MOBILE STYLES*/
@media (min-width: 320px) and (max-width: 767px) {

  body,
  .tryout {
    background-image: none;
    border-radius: 54px 0;

    .tryout-title {
      font-size: 42px;
      line-height: 49px;
    }
  }

  .intro {
    background: none;

    .tdLogoMobile {
      display: block;
    }

    .tdLogo {
      display: none;
    }

    .signUpForm {
      display: flex;
      flex-direction: column;
      width: 100%;

      .inputDiv {
        width: 100%;
        padding-bottom: 10px;
      }
    }
  }

  .features {
    padding: 52px 0 50px !important;
    background: none;
    background-color: #edeef2;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;

    .featuresTitle {
      font-size: var(--font-size-32);
      margin-top: var(--space-24);
      line-height: 38px;
    }

    p {
      margin-bottom: 0;
      font-size: var(--font-size-16) !important;
    }

    &__row {
      margin-top: 0 !important;
    }

    &__content {
      order: 1;
      margin-bottom: 0;
    }

    &__img {
      padding: 50px 0 var(--space-24);
    }

    .emp-reverse {
      display: flex;
      order: 1;
    }

    .organization {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .solutions {
    padding-top: 72px;

    .solutionsTitle {
      margin-bottom: var(--space-10);
    }

    p {
      font-size: var(--font-size-16) !important;
    }

    .our-solutions {
      row-gap: 52px;

      .solTitle {
        font-size: var(--font-size-24);
        margin-bottom: var(--space-16);
      }
    }
  }

  .enterprise {
    margin-bottom: 50px;

    .enterprise-title {
      font-size: var(--font-size-24);
      line-height: 32px;
    }

    .container {
      max-width: 100% !important;
      padding: 50px var(--space-32) 30px;
      border-radius: 0;
    }

    .left-col {
      padding: 0;
    }

    .right-col {
      text-align: center;
      padding: 18px 0 0;
      background: none;
    }

    p:not(.heading) {
      line-height: 22px;
    }
  }

  .feedback {
    padding-top: 0;
    margin-bottom: 0;

    .row {
      --mdb-gutter-x: 2rem !important;
    }

    .feedback-title {
      font-size: var(--font-size-32);
      line-height: 38px;
      margin-top: 0;
    }

    p {
      font-size: var(--font-size-16);
    }

    .left-col {
      padding: 0;

      .row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 0 !important;
        width: 100%;
        gap: 20px;
        max-width: 380px;

        .col-xs-6 {
          width: 100% !important;
          padding: 0 !important;

          .rating {
            .stars img {
              width: 100%;
              margin-bottom: 5px;
            }

            div:last-child {
              height: 30px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
      }
    }

    .right-col {
      background: none;
      background-color: #27c26c;
      margin-top: 70px;

      .client-img {
        margin-left: -1.25rem;
      }

      .feedback-col {
        padding: 0 0 40px;
      }

      p {
        margin-top: -10px;
      }
    }

    .dots {
      display: none;
    }
  }

  .tryout.container-fluid {
    padding: 52px var(--space-32);

    .row {
      --mdb-gutter-x: 0;
    }

    p {
      font-size: var(--font-size-16);
      line-height: 22px;
    }
  }
}

.or-cont {
  color: var(--color-secondary-darkgray);
  font-size: 14px;
  letter-spacing: .25px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}

.googleWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.divider {
  display: block;
  height: 1px;
  width: 100%;
  background: rgba(88, 90, 110, 0.1);
}


/* 2dm */

.ratingImgResponsive {
  object-fit: contain;
  max-width: 100%;
}
