.NavTab {
  $self-navtab: &;
  padding: 0;
  list-style: none;

  &.expanded {
    @media screen and (max-width: 1024px) {
      max-height: 800px;

      #{$self-navtab}__ExpandIcon {
        transform: rotate(180deg);
      }
    }
  }

  &.active {
    --nav-tab-text-color: var(--color-ocean-blue);

    @media screen and (min-width: 1024px) {
      border-bottom-color: var(--color-ocean-blue);
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-height: 88px;
    padding: 0;
    overflow: hidden;
    border-bottom: 1px solid rgba(88, 90, 110, 0.2);
    transition: max-height 0.35s ease-in-out;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__Title {
    font-size: 20px !important;
    color: var(--color-onyx) !important;

    &.active {
      color: var(--color-ocean-blue) !important;
    }

    @media screen and (max-width: 1024px) {
      font-weight: 500 !important;

      &:hover {
        color: var(--color-gray-info) !important;
      }
    }
  }

  &__ExpandIcon {
    &.active {
      color: var(--color-ocean-blue) !important;
    }

    @media screen and (max-width: 1024px) {
      transition: transform 0.4s ease-in-out;
    }
  }
}

.NavTabBox {
  $self: &;
  height: 100%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    padding: 0;
    border-bottom: 0;
    flex-wrap: wrap;
  }

  &__Box {
    cursor: pointer;
    column-gap: 4px;
    padding: 0;
    height: 100%;

    &:hover {
      & > * {
        color: var(--color-ocean-blue) !important;
      }
    }

    & > * {
      color: var(--color-onyx) !important;
      font-weight: 600 !important;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 85px;
      justify-content: space-between !important;
      padding: 0;

      &:hover {
        & > * {
          color: var(--color-gray-info) !important;
        }
      }
    }
  }

  &__Link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 var(--space-12);
    outline-offset: 0;

    &:hover {
      & > * {
        color: var(--color-ocean-blue) !important;
      }
    }

    & > * {
      color: var(--color-onyx) !important;
      font-weight: 600 !important
    }

    @media screen and (max-width: 1024px) {
      height: 88px;
      padding: 0;
    }
  }

  &[aria-expanded='true'] {
    #{$self}__Dropdown {
      display: block;
      z-index: 100;

      &__Box {
        animation: slideInDown 0.35s ease-out forwards;

        @media screen and (max-width: 1024px) {
          animation: none;
        }
      }
    }

    #{$self}__Box {
      @media screen and (max-width: 1024px) {
        display: none;
        & > * {
          color: var(--nav-tab-text-color, var(--color-primary)) !important;
        }
      }

    }
  }

  &__Dropdown {
    $self-dropdown: &;
    width: auto;
    position: absolute;
    top: 80px;
    left: auto;
    display: none;
    padding-top: 2px;
    z-index: -1;
    overflow-y: clip;
    padding-bottom: 50px;
    margin-left: -48px;

    &.FullWidth {
      left: 0;
      right: 0;
      width: 100%;
      margin-left: 0;
    }

    &__CardContent {
      padding: 30px !important;

      @media screen and (max-width: 1024px) {
        padding: 0 !important;
      }
    }

    &__Box {
      background: #fff !important;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.10) !important;
      transform: translateY(-105%);

      &__Content {
        gap: 45px;
        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
          gap: 15px;
        }
      }

      @media screen and (max-width: 1024px) {
        margin: 0;
        transform: none;
        border-radius: 0 !important;
        box-shadow: none !important;
      }
    }

    @media screen and (max-width: 1024px) {
      position: relative;
      top: 0;
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
      display: block;
    }
  }

  &__NavColumn {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &__Title {
      margin-bottom: var(--space-16) !important;
      font-size: 20px !important;
      font-weight: 900 !important;
      line-height: 20px;
      color: var(--color-ash) !important;

      @media screen and (max-width: 1024px) {
        margin-bottom: 15px;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 15px;

      ul li {
        margin-bottom: var(--space-16);
      }

      ul li:last-child {
        margin-bottom: 0;
      }
    }
  }

  @keyframes slideInDown {
    from {
      transform: translateY(-105%);
    }
    to {
      transform: translateY(0);
    }
  }
}
