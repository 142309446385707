/* Header block */
.header {
  width: 100%;
  display: block;
  padding-top: var(--space-20);
  padding-bottom: 0;
  position: absolute;
  top: 0;
  left: 0;

  &__logo {
    margin: 0;

    &--desktop {
    }

    &--mobile {
      display: none;
    }
  }

  &.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    padding-bottom: var(--space-20);
    background: #fff;
    z-index: 100;
    animation: slidein 0.3s ease-in-out;
  }

  &.hide {
    animation: slideout 0.3s ease-in-out forwards;
  }
}

@keyframes slidein {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideout {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
    position: relative;
  }
}

@media (max-width: 767px) {
  .header {
    padding: var(--space-16) 0 !important;
  }
}
